<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Name" v-model="name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full lg:w-1/2">
          <vs-input type="number" @focus="$event.target.select()" class="w-full" v-validate="'required'" name="capacity" label="Capacity (m3)" v-model="capacity"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('capacity')">{{errors.first('capacity')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full lg:w-1/2">
          <vs-input type="number" @focus="$event.target.select()" class="w-full" v-validate="'required'" name="weight_capacity" label="Capacity (kg)" v-model="weight_capacity"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('weight_capacity')">{{errors.first('weight_capacity')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-textarea class="w-full" name="description" label="Description" v-model="description"></vs-textarea>
          <span class="text-danger text-sm" v-show="errors.has('description')">{{errors.first('description')}}</span>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save" class='mr-1'>Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  data(){
    return {
      name: "",
      capacity: 0,
      weight_capacity: 0,
      description: ""
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'vehicle_types/store',
      dispatchUpdate: 'vehicle_types/update',
      dispatchShow: 'vehicle_types/show'
    }),
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          name: this.name,
          capacity: this.capacity,
          weight_capacity: this.weight_capacity,
          description: this.description
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.name = data.name
      this.capacity = data.capacity
      this.weight_capacity = data.weight_capacity
      this.description = data.description
    }
  },
  mounted(){
    // console.log(this.ids)
    if (this.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>